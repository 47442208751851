import React from "react";
import styled from "@emotion/styled";
import "sanitize.css";

import { Card } from "./components/Card";
import { Main } from "./components/Main";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Admin } from "./components/Admin";
import { Notifications } from "./components/Notifications";
import { Warning } from "./components/Warning";
import { Game } from "./components/Game";
import { Donate } from "./components/Donate";
import { Table } from "./components/Table";
import { useEagerConnect } from "./hooks/useWallet";

const Wrapper = styled.div`
  background-image: url("/Space_Background.gif");
  color: ${({ theme }) => theme.text};
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: ${({ theme }) => theme.fonts.body};
  h1,
  h2,
  h3 {
    margin: 0;
    line-height: 1;
  }
`;

export const App = () => {
  useEagerConnect();

  return (
    <Wrapper>
      <Notifications />
      <Warning />
      <Header />
      <Main>
        <Card>
          <Game />
          <Table />
          <Donate />
          <Admin />
        </Card>
        <br />
        <p
          style={{
            fontStyle: "italic",
            fontSize: "0.4em",
            opacity: 0.91,
            color: "gray",
          }}
        >
          The Information Provided On This Website Does Not Constitute Investment Advice, Financial Advice, Trading
          Advice, Or Any Other Sort Of Advice And You Should Not Treat Any Of The Website’s Content As Such. The
          MetaFlip Team Does Not Recommend That Any Cryptocurrency or Non-Fungible Tokens Should Be Bought, Sold, Or
          Held By You. Do Conduct Your Own Due Diligence And Consult Your Financial Advisor Before Making Any Investment
          Decisions. By Purchasing a MetaFlip, You Agree That You Are Not Purchasing A Security Or Investment And You
          Agree To Hold The Team Harmless And Not Liable For Any Losses Or Taxes You May Incur. You Also Agree That The
          Team Is Presenting The Token “As Is” And Is Not Required To Provide Any Support Or Services. You Should Have
          No Expectation Of Any Form From MetaFlip And Its Team. Although MetaFlip Is A Community Driven Social
          Experiment And Not A Registered Digital Currency, The Team Strongly Recommends That Citizens In Areas With
          Government Bans On Crypto Do Not Purchase It Because The Team Cannot Ensure Compliance With Your Territories
          Regulations. Always Make Sure That You Are In Compliance With Your Local Laws And Regulations Before You Make
          Any Purchase. Names, Characters, Places, And Incidents Either Are The Product Of The Communities Imagination
          Or Are Used Fictitiously. Any Resemblance To Actual Persons, Characters, Business Establishments, Events, Or
          Locales Is Entirely Coincidental.
        </p>
      </Main>
      <Footer />
    </Wrapper>
  );
};
