import React, { useEffect, useState } from "react";
import axios from "axios";
import Web3 from "web3";

export const Table = ({ contractAddress, apiKey }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          "https://api.bscscan.com/api?module=logs&action=getLogs" +
            `&fromBlock=0&toBlock=latest&address=0x1EbdEA878A8C18EA03FaD2711316cF7Beb804EAA` +
            "&topic0=0x883f4d0c1f1f46295aca1500eb9887530d302925a814aa0a107fc8ca8b77971d" +
            `&apikey=C2SPSW5W72MRAZG5J1SWY3I7ZYMVPPUS4J`
        );

        const web3 = new Web3(Web3.givenProvider);

        const txData = response.data.result
          .slice(-20)
          .reverse()
          .map((tx) => {
            const address = `0x...${tx.topics[1].slice(60)}`;
            const win = web3.eth.abi.decodeParameters(["bool"], tx.data)[0];
            const amount = web3.utils.fromWei(tx.data.slice(66), "ether");
            return {
              address,
              win,
              amount,
              hash: tx.transactionHash,
              blockNumber: tx.blockNumber,
              from: tx.topics[1],
              to: tx.topics[2],
              value: web3.utils.fromWei(tx.data.slice(2, 66), "ether"),
            };
          });

        setTransactions(txData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTransactions();
  }, [contractAddress, apiKey]);

  return (
    <div>
      <table className="stats-table">
        <thead></thead>
        <tbody>
          {transactions.map((tx) => (
            <tr key={tx.hash}>
              <td>{tx.address}</td>
              <td>{tx.win ? "True" : "False"}</td>
              <td>{Number(tx.amount).toFixed(4)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
