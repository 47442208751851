import React from "react";
import styled from "@emotion/styled";
import logo from "./logo.png";
import { ConnectButton } from "./ConnectButton";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 120px;
  margin-right: 10px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: ${({ theme }) => theme.space.m}px;
`;

export const Header = () => (
  <StyledHeader>
    <LogoContainer>
      <LogoImage src={logo} alt="Logo" />
    </LogoContainer>
    <ConnectButton />
  </StyledHeader>
);
