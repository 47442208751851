import React, { useEffect, useState } from "react";
import { Button } from "./Button";
import { useWallet } from "../hooks/useWallet";
import { NumberInput } from "./NumberInput";
import { useCoinFlipContract } from "../hooks/useContract";
import { useFunction } from "../hooks/useFunction";

const useOwnerAddress = () => {
  const contract = useCoinFlipContract();
  const [ownerAddress, setOwnerAddress] = useState(null);

  useEffect(() => {
    if (!contract) {
      return;
    }
    contract.functions.owner().then(setOwnerAddress);
  }, [contract]);

  return ownerAddress;
};

export const Donate = () => {
  const contract = useCoinFlipContract();
  const { account } = useWallet();
  const [deposit, setDeposit] = useState(0.05);
  const ownerAddress = useOwnerAddress();
  const doDeposit = useFunction("deposit", deposit);
  const isOwner = ownerAddress && ownerAddress[0] === account;

  if (!contract || isOwner) {
    return null;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <hr style={{ marginTop: 20, marginBottom: 20, opacity: 0.34 }} />
      <h2>Donate to the Prize Pool!</h2>
      <p>
        Donate: <NumberInput onChange={setDeposit} value={deposit} />
        <Button onClick={doDeposit}>Confirm</Button>
        <br />
      </p>
    </div>
  );
};
