//export const COINFLIP_ADDRESS = "0x6d9AeE60C87f97BF3Cdce3d59C6c6C2e80F301ca"; // VRF v1 on Kovan
export const COINFLIP_ADDRESS = "0x1EbdEA878A8C18EA03FaD2711316cF7Beb804EAA"; // VRF v2 on Rinkeby
export const NFT_ADDRESS = "0xf262c22ca5B2f1F4Ba5FefA6453Ff1c030A99b2";
export const SUPPORTED_CHAINS = [56];
export const WAITFOR_CONFIRMATIONS = 2;
//0x47F745655A6966D0022E480673602e261675e334
//0xC0f75211d7b8f2a4569c6B1b53ec817B72507d0B
//0x5ce43250E13831Aa7666A3aBDF8292A31042Ac5f
//0xAfC571BEC698982B0697883f978D323754486EE4
//0x83281a8CB4C127fe015E3E7fEFb8AfDa015493fe
//0xA9F21d0EdACa11Fc48b18250772B3b9AE8c367Db
//0xCeFc3043349313D41E3CF0C495816DB5192b3800
//0x7A07Fd5e311CffbEa560975C3F41b2133855A719
