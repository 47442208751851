import React, { useState } from "react";
import styled from "@emotion/styled";
import Color from "color";
import { formatEther } from "@ethersproject/units";

import { ConnectButton } from "./ConnectButton";
import { Eth } from "./Eth";
import { useWallet } from "../hooks/useWallet";
import { useAppContext } from "../AppContext";
import { useCoinFlipContract } from "../hooks/useContract";
import { useFunction } from "../hooks/useFunction";
import { useEventCallback } from "../hooks/useEventCallback";
import logo from "../logo.png";
import tails from "../tails.png";
import sponsor from "./sponsor.png";

const StyledCoin = styled.button`
  height: 6.1rem;
  width: 6.1rem;
  border-radius: 50%;
  border: 0;
  background-color: ${({ theme }) => theme.colors.coin};
  color: white;
  font-size: 3.3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ marginRight, theme }) => (marginRight ? `${theme.space.l}px` : undefined)};
  cursor: pointer;
  outline: 0;
  translate: transform 150ms ease-in-out, background-color 150ms ease-in-out;
  &:hover {
    transform: scale(1.1);
    background-color: ${({ theme }) => Color(theme.colors.coin).darken(0.2).toString()};
  }
`;

const StyledCoinWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => `${theme.space.m}px`};
  margin-bottom: ${({ theme }) => `${theme.space.m}px`};
  justify-content: center;
`;

const TailsCoin = ({ onClick }) => {
  return (
    <StyledCoin onClick={onClick} marginRight>
      <img src={tails} alt="tails" width="100" height="100" />
    </StyledCoin>
  );
};

const HeadsCoin = ({ onClick }) => {
  return (
    <StyledCoin onClick={onClick} marginRight>
      <img src={logo} alt="heads" width="100" height="100" />
    </StyledCoin>
  );
};

const StyledButton = styled.button`
  background-color: ${({ theme, disabled, selected }) =>
    selected ? theme.colors.selected : disabled ? theme.colors.grey : theme.colors.complementary};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGrey : theme.colors.white)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  display: ${({ block }) => (block ? "flex" : "inline-flex")};
  margin: 0;
  border: 2px solid ${({ theme }) => theme.colors.black};
  box-sizing: border-box;
  padding: ${({ block }) => (block ? "12px 14px" : "9px 12px")};
  width: ${({ block }) => (block ? "100%" : undefined)};
  justify-content: ${({ block }) => (block ? "center" : undefined)};
  border-radius: ${({ theme }) => theme.sizes.borderRadius.m};
  transition: background-color 150ms ease-in-out;
  font-size: ${({ block }) => (block ? "0.9rem" : "0.8rem")};
  text-transform: uppercase;
  letter-spacing: 0.09em;
  font-weight: 700;
  color: ${({ theme }) => theme.buttonText};
  &:hover {
    transform: scale(1.1);
    background-color: ${({ theme }) => Color(theme.colors.selected).darken(0.2).toString()};
  }
`;

const Button = ({ disabled, selected, ...props }) => {
  return <StyledButton disabled={disabled} selected={selected} {...props} />;
};

export const Game = () => {
  const { isActive, account } = useWallet();
  const {
    balance,
    profit,
    playerwin,
    playerloss,
    getPlayerWin,
    getPlayerLoss,
    getContractBalance,
    getPlayerFlips,
    getProfit,
    getWins,
    getLoses,
    payout,
    playerflips,
    addNotification,
  } = useAppContext();
  const contract = useCoinFlipContract();
  const [betAmount, setBet] = useState();
  const [betResults, setBetResults] = useState([]);

  const [betChoice, setBetChoice] = useState();
  const { contractBalance } = useAppContext();
  const { contractFullBalance } = useAppContext();
  const { wins } = useAppContext();
  const { loses } = useAppContext();
  const { flips } = useAppContext();

  const handleBetChoice = (choice) => {
    setBetChoice(choice);
  };

  const setMinBet = () => setBet(0.01);
  const set1Bet = () => setBet(0.02);
  const set2Bet = () => setBet(0.03);
  const set3Bet = () => {
    if (contractBalance >= 0.05) {
      setBet(0.05);
    }
  };
  const set4Bet = () => {
    if (contractBalance >= 0.1) {
      setBet(0.1);
    }
  };
  const setMaxBet = () => setBet(contractBalance);

  const selectedMin = betAmount === 0.01;
  const selected1 = betAmount === 0.02;
  const selected2 = betAmount === 0.03;
  const selected3 = betAmount === 0.05;
  const selected4 = betAmount === 0.1;
  const selectedMax = betAmount === contractBalance;

  useEventCallback(
    "BetResult",
    (address, win, value) => {
      setBetResults((betResults) => [{ address, win, value }, ...betResults.slice(0, 99)]);
      if (address === account) {
        getProfit();
        getContractBalance();
        getWins();
        getPlayerWin();
        getPlayerFlips();
        addNotification({
          title: win ? `  🏆  You won ${formatEther(value)} BNB!` : `⛔ You lost. Let's try again!`,
          isSuccess: win,
          isError: !win,
        });
      } else {
        getContractBalance();
        getLoses();
        getPlayerLoss();
        getPlayerFlips();
      }
    },
    [account, setBetResults]
  );

  const doFlip = useFunction("bet", betAmount, [betChoice]);
  const collectFunds = useFunction("withdrawPlayerBalance");

  if (!isActive || !account) {
    return (
      <div style={{ textAlign: "center" }}>
        <br />
        <p>#1 MOST TRUSTED COIN FLIP ON BNB CHAIN!</p>
        <a>
          <br />
          <img src={logo} alt="logo" width="50%" />
        </a>
        <br />
        <br />
        <br />
        <ConnectButton block>Connect your wallet to start flipping!</ConnectButton>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        Recent Flips:
      </div>
    );
  }

  if (!contract) {
    return <p>Could not connect to the contract</p>;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <h2 style={{ color: "darkorange" }}>Welcome to MetaFlip!</h2>
      <StyledCoinWrapper>
        &nbsp;
        <HeadsCoin onClick={() => handleBetChoice(0)} />
        &nbsp;
        <TailsCoin onClick={() => handleBetChoice(1)} />
      </StyledCoinWrapper>
      <div style={{ textAlign: "center" }}>
        <Button selected={betChoice === 0} onClick={() => handleBetChoice(0)}>
          flip Heads
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button selected={betChoice === 1} onClick={() => handleBetChoice(1)}>
          flip Tails
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <hr style={{ border: "1px solid #333", margin: "20px 0" }} />
      <div style={{ textAlign: "center", marginTop: "0px" }}>
        <Button selected={selectedMin} onClick={setMinBet} disabled={contractBalance < 0.01}>
          risk .01
        </Button>
        &nbsp;&nbsp;
        <Button selected={selected1} onClick={set1Bet} disabled={contractBalance < 0.02}>
          risk .02
        </Button>
        &nbsp;&nbsp;
        <Button selected={selected2} onClick={set2Bet} disabled={contractBalance < 0.03}>
          risk .03
        </Button>
      </div>
      <div style={{ textAlign: "center", marginTop: "5px" }}>
        <Button selected={selected3} onClick={set3Bet} disabled={contractBalance < 0.05}>
          risk .05
        </Button>
        &nbsp;
        <Button selected={selected4} onClick={set4Bet} disabled={contractBalance < 0.1}>
          risk .10
        </Button>
        &nbsp;
        <Button selected={selectedMax} onClick={setMaxBet} disabled={contractBalance < 0.01}>
          Max risk
        </Button>
        <br />
      </div>
      <p
        style={{
          marginTop: 2,
          fontStyle: "italic",
          fontSize: "0.7em",
          opacity: 0.91,
        }}
      >
        Maximum Risk Amount: <Eth>{contractBalance}</Eth>
        <br />
        <br />
        <Button
          disabled={!betAmount || (betChoice !== 0 && betChoice !== 1)}
          onClick={() => doFlip(betAmount, betChoice)}
          block
        >
          Flip
        </Button>
        Double or nothing minus 3% play fee.
        <br />
        <a href="https://rareboard.com/metaraffes">
          Sponsored By:
          <br />
          <img src={sponsor} alt="logo" width="100%" />
        </a>
      </p>
      <hr style={{ border: "1px solid #333", margin: "21px 0" }} />
      <table className="stats-table">
        <caption>Player Dashboard</caption>
        <tr>
          <th>Stats</th>
          <th>Value</th>
        </tr>
        <tr>
          <td>Wallet Balance</td>
          <td>
            <Eth>{balance}</Eth>
          </td>
        </tr>
        <tr>
          <td>Your Total Flips</td>
          <td>{playerflips}</td>
        </tr>
        <tr>
          <td>Your Total Wins</td>
          <td>{playerwin}</td>
        </tr>
        <tr>
          <td>Your Total Losses</td>
          <td>{playerloss}</td>
        </tr>
      </table>
      <p className="pending-rewards">
        Pending Rewards:
        <Eth>{profit}</Eth>
      </p>
      {profit && profit !== "0.0" ? (
        <Button onClick={collectFunds}>Collect Rewards</Button>
      ) : (
        <Button disabled>No Rewards to Collect</Button>
      )}
      <br />
      <hr style={{ border: "1px solid #333", margin: "20px 0" }} />
      <br />
      <table className="stats-table">
        <thead>
          <tr>
            <th colSpan="2">
              <b>Global Stats:</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Flips:</td>
            <td>{flips}</td>
          </tr>
          <tr>
            <td>Total Wins:</td>
            <td>{wins}</td>
          </tr>
          <tr>
            <td>Total Losses:</td>
            <td>{loses}</td>
          </tr>
          <tr>
            <td>Total Paid Out:</td>
            <td>
              <Eth>{payout}</Eth>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="pending-rewards">
        Prize Pool:
        <Eth>{contractFullBalance}</Eth>
      </p>
      <hr style={{ border: "1px solid #333", margin: "20px 0" }} />
      <p
        style={{
          fontStyle: "italic",
          fontSize: "0.9em",
          opacity: 0.91,
        }}
      >
        Mint a MetaFlip NFT to get dividends from the game's tax!
        <br />
      </p>
      <Button onClick={() => (window.location.href = "https://faq.metaflip.fun")}>FAQ</Button>&nbsp;&nbsp;&nbsp;
      <Button onClick={() => (window.location.href = "https://mint.metaflip.fun")}>MINT</Button>&nbsp;&nbsp;&nbsp;
      <Button onClick={() => (window.location.href = "https://claim.metaflip.fun")}>NFT REWARDS</Button>
      <br />
      <p
        style={{
          fontStyle: "italic",
          fontSize: "0.7em",
          opacity: 0.91,
        }}
      >
        Play at your own risk!
      </p>
      <div>
        <span className="text">
          🟢<b>Live Flip Feed:</b>
        </span>
      </div>
      <table className="stats-table">
        <thead>
          <tr>
            <th>Address</th>
            <th>Win</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {betResults.map((betResult, index) => (
            <tr key={index}>
              <td>0x...{betResult.address.substring(account.length - 5)}</td>
              <td>{betResult.win ? "True" : "False"}</td>
              <td>{formatEther(betResult.value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
